
<template>
    <table class="table table-sm table-bordered mb-0" style="table-layout: fixed;font-size:80%" v-if="dsItemsProperties.data && (alwaysShowGrid || (dsItemsProperties.data.length > 0 && !readOnly))">
        <tbody>
            <template v-for="(item,index) in dsItemsProperties.data" >
                <tr  v-if="showHeader(index)" class="mb-1">
                    <td colspan="2" class="fw-bold">{{item.GroupByHeader}}</td>
                </tr>
                <tr>
                    <td style="width:50%">{{item.Caption}} <template v-if="item && item.RequiredAtStep == props.step">*</template></td>
                    <td style="width:50%">
                        <EditorItem :item="item" @blur="save()" :valueSetter="propertySetter" :valueGetter="propertyGetter" style="width:100%" class="border-0" />
                    </td>
                </tr>
                
            </template>
        </tbody>
    </table>
    <small class="ms-1" v-if="hasRequiredFields() && dsItemsProperties.state._isLoaded && !readOnly" >{{$t("* Required")}}</small>    
    
    <template v-if="dsItemsProperties.data.length > 0 && readOnly" v-for="(property,index) in dsItemsProperties.data">
        <h6 v-if="showHeaderReadOnly(index)" class="mb-0 mt-2">{{property.GroupByHeader}}</h6>
        <div class="d-md-flex justify-content-start col-12 mb-0" v-if="readOnly && (property.Value != null || property.IntValue != null || property.DateValue != null) "> 
            <span class="me-2 text-nowrap " :title="property.Caption">{{property.Caption}}:</span>
            <span v-if="property.DataType == 'text'" :title="property.Value"> {{property.Value}}</span>
            <span v-else-if="property.DataType == 'number'" :title="property.IntValue ?? property.Value"> {{property.IntValue ?? property.Value}}</span>
            <span v-else-if="property.DataType == 'bool'" > 
                <i class="bi bi-check-square" v-if="property.IntValue == 1"></i>  
                <i class="bi bi-square" v-else></i>  
            </span>
            <span v-else-if="property.DataType == 'date'"> {{utils.formatDate(property.DateValue,'Short Date')}}</span>
            <span v-else-if="property.DataType == 'datetime'"> {{utils.formatDate(property.DateValue,'General Date Short Time')}}</span>
        </div>
    </template>
</template>

<script setup>
    import { computed, ref, onMounted, onUnmounted } from 'vue';
    import EditorItem from 'o365.vue.components.InputEditors.Item.vue';
    import { getOrCreateDataObject, getOrCreateProcedure, deleteDataObject, getDataObjectById } from 'o365.vue.ts';
    import utils from 'o365.modules.utils.js';

    const props = defineProps({
        step: Number,
        readOnly: false,
        itemId: Number,
        viewName:String,
        uniqueTable:String,
        alwaysShowGrid: {
            type: Boolean,
            default: false,
        }
    });

    var vViewName = props.viewName?props.viewName:'aviw_Assets_ObjectsProperties'
    var vUniqueTable = props.uniqueTable?props.uniqueTable:'atbv_Assets_ObjectsProperties'

    const emit = defineEmits(["propertySaved", "propertyDeleted"]);
    
    const vItemPropertyId = vViewName + crypto.randomUUID();

    const dsItemsProperties = getOrCreateDataObject({
        id: vItemPropertyId,
        viewName: vViewName,
        maxRecords: -1,
        uniqueTable: vUniqueTable,
        allowInsert: true,
        allowDelete: true,
        allowUpdate: true,
        loadRecents: false,
        distinctRows: true,
        fields:
             [{name: "ID", type: "number" },
             {name: "PropertyName", type: "string" },
             {name: "Value", type: "string" },
             {name: "DateValue", type: "date" },
             {name: "DataType", type: "string" },
             {name: "Caption", type: "string" },
             {name: "Sequence", type: "number", "sortOrder": 1, "sortDirection": "asc", },
             {name: "GroupByHeader", type: "string", "sortOrder": 2, "sortDirection": "asc"},
             {name: "LookupValues", type: "string" },
             {name: "Required", type: "boolean" },
             {name: "Config", type: "string" },
             {name: "HideUntilStep", type: "number" },
             ]
    });

    
    defineExpose({
        save,
        reload
    });
    
    function reload(){
        dsItemsProperties.recordSource.whereClause = "Item_ID = " + props.itemId;
        dsItemsProperties.load();
    }

    async function save(){
        await dsItemsProperties.save();
    }

    window.addEventListener("beforeunload", function(event) {
      dsItemsProperties.save();
    });

    window["dsItemsProperties"] = dsItemsProperties;
    
    dsItemsProperties.recordSource.whereClause = "Item_ID = " + props.itemId ;
    if(props.step >= 0){
        dsItemsProperties.recordSource.whereClause += " AND ISNULL(HideUntilStep,0) = " + props.step;
    }
    dsItemsProperties.load();

    onUnmounted(() => {
        deleteDataObject(getDataObjectById(vItemPropertyId)); 
        beforeSaveCb && beforeSaveCb();
        afterSaveCb && afterSaveCb();
        afterDeleteCb && afterDeleteCb();
    });
    
    const beforeSaveCb = dsItemsProperties.on("BeforeSave",(pRow)=>{
        pRow.values["Item_ID"] = props.itemId;
    })

    const afterSaveCb = dsItemsProperties.on("AfterSave", () => {
        emit("propertySaved");
    });

    const afterDeleteCb = dsItemsProperties.on("AfterDelete", () => {
        emit("propertyDeleted");
    });
    
    function propertySetter(pItemEditor, pItem, pValue) {
        switch (pItemEditor.valueType) {
            case 'bool':
                pItem.IntValue = pValue === true ? 1 : null;
                break;
            case 'number':
                pItem.Value = pValue;
                pItem.IntValue = pValue;
                break;
            case 'date':
                pItem.Value = pValue;
                pItem.DateValue = pValue;
                break;
            case 'datetime':
                pItem.Value = pValue;
                pItem.DateTimeValue = pValue;
                break;
            default:
                pItem.Value = pValue;
        }

        if (pItemEditor.displayMember && pItemEditor.displayValue && typeof pValue === 'number') {
            pItem.Value = pItemEditor.displayValue;
            pItem.IntValue = pValue;
        }
    }

    function propertyGetter(pItemEditor, pItem) {
        if (pItemEditor.valueType === "date") {
            return pItem.DateValue;
        } else if (pItemEditor.valueType === "datetime") {
            return pItem.DateTimeValue;
        }
        /*if (['date','datetime'].includes(pItemEditor.valueType)) {
            return pItem.DateValue;
        }*/ 
        else if (pItemEditor.valueType == 'bool' && pItem.IntValue !== null) {
            return pItem.IntValue === 1 ? true : false;
        } else if (pItemEditor.valueType === 'number') {
            return pItem.IntValue ?? pItem.Value;
        } else {
            return pItem.Value;
        }
    }

    /** Based on row DataType figure out if the editor should be shown in renderer or editor slot */
    function isEditable(row) {
        if (row == null) { return false; }
        if (row.isNewRecord || row.isBatchRecord) { return true; }

        if (row.LookupValues) { return false; }
        switch(row.DataType) {
            case 'bool':
                return false;
            default:
                return true; 
        }
    }
    

    function showHeader(index){
        if(index > 0){
            return dsItemsProperties.data[index].GroupByHeader != dsItemsProperties.data[index-1].GroupByHeader;
        }else if(dsItemsProperties.data[index].GroupByHeader){
            return true;
        }else{
            return false;
        }
    }

    
    function showHeaderReadOnly(index){
        if(index > 0){
            return dsItemsProperties.data[index].GroupByHeader != dsItemsProperties.data[index-1].GroupByHeader && hasPropertyValues(dsItemsProperties.data[index].GroupByHeader);
        }else if(dsItemsProperties.data[index].GroupByHeader && hasPropertyValues(dsItemsProperties.data[index].GroupByHeader)){
            return true;
        }else{
            return false;
        }
    }

    function hasPropertyValues(pGroupByHeader){
        return dsItemsProperties.data.filter(x=> x.GroupByHeader == pGroupByHeader && (x.Value != null || x.DateValue != null || x.IntValue != null)).length
    }

    function hasRequiredFields(){
        return dsItemsProperties.data.filter(x=> x && x.RequiredAtStep == props.step).length > 0;
    }

</script>